@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  background: #F7FAFD;
  height: 100vh;
}

html {
  font-family: 'Nunito', sans-serif;
}

.phoneInput .react-tel-input {
  margin-right: 10px;
}

.phoneInput .react-tel-input .flag-dropdown {
  margin-right: 10px;
  height: 35px;
  background: transparent !important;
}

.phoneInput .react-tel-input .flag-dropdown.open .selected-flag {
  background: transparent !important;
}

.phoneInput .react-tel-input .selected-flag:hover {
  background: transparent !important;
}

.CountrySelectorError .react-tel-input .flag-dropdown {
  border: 2px solid #E7515A !important;
  border-radius: 6px 0 0 6px !important;
}

.CountrySelector .react-tel-input .flag-dropdown {
  border: 2px solid #D8D8D8 !important;
  border-radius: 6px 0 0 6px !important;
}

.phoneInput .react-tel-input:has(.form-control:focus) .flag-dropdown {
  border: 2px solid #13151F !important;
  border-radius: 6px 0 0 6px !important;
}

.CountrySelector .react-tel-input .form-control {
  width: 100% !important;
  border: 2px solid #D8D8D8 !important;
  border-radius: 6px !important;
}

.CountrySelectorError .react-tel-input .form-control {
  width: 100% !important;
  border: 2px solid #E7515A !important;
  border-radius: 6px !important;
}

.phoneInput .react-tel-input .form-control:focus {
  border: 2px solid #13151F !important;
}

.recipient .react-tel-input .form-control {
  background: transparent !important;
}

.preview img {
  height: auto !important;
  max-height: 100% !important;
}

/* .react-pdf__Document {
  display: flex;
  flex-direction: column;
  align-items: center;
} */

.react-pdf__Page {
  min-width: 100% !important;
  max-width: 100% !important;
  height: calc(100vh - 245px) !important;
  border: 2px solid black;
}

.react-pdf__Page canvas {
  height: calc(100vh - 250px) !important;
  width: 100% !important;
}

.react-pdf__message {
  color: white;
}

.react-pdf__Page__textContent {
  height: calc(100vh - 245px) !important;
  width: 100% !important;
}

.react-pdf__Page__annotations {
  height: calc(100vh - 245px) !important;
  width: 100% !important;
}

.ant-picker-dropdown {
  inset-block-start: 112px !important;
}

.ant-picker-dropdown-range {
  padding: 0 !important;
}

.ant-picker-range-arrow {
  display: none !important;
}

.ant-picker-range {
  width: 457px !important;
  border-radius: 8px 8px 0 0 !important;
  border: 1px solid rgba(5, 5, 5, 0.06) !important;
}

.ant-picker:hover {
  border: 1px solid rgba(5, 5, 5, 0.06) !important;
}

.ant-picker-panel-container {
  border-radius: 0 0 8px 8px !important;
}

.ant-btn-primary {
  background-color: #38A6DE !important;
}

.ant-picker-panel-container {
  width: 457px !important;
}


.ant-picker-year-panel {
  width: 457px !important;
}

.ant-picker-month-panel {
  width: 457px !important;
}

.ant-picker-body {
  width: 457px !important;
}

.ant-picker-decade-panel {
  width: 457px !important;
}

.infoMsg a {
  color: #38A6DE !important;
  font-weight: 800;
  cursor: pointer;
}

@media (min-width: 0px) and (max-width: 820px) {
  .ant-picker-panel-container {
    width: 350px !important;
  }

  .ant-picker-year-panel {
    width: 350px !important;
  }

  .ant-picker-month-panel {
    width: 350px !important;
  }

  .ant-picker-range {
    width: 350px !important;
  }

  .ant-picker-body {
    width: 350px !important;
  }

  .ant-picker-decade-panel {
    width: 350px !important;
  }

  .ant-picker-date-panel {
    width: 350px !important;
  }

  .ant-picker-date-panel .ant-picker-body .ant-picker-content {
    width: 315px !important;
  }

  .ant-picker-datetime-panel {
    flex-direction: column !important;
  }

  .ant-picker-time-panel-column {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    flex-direction: column !important;
  }
}

.suggestions-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 999;
  background-color: white;
  border: 1px solid #ccc;
  max-height: 200px;
  overflow-y: auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.suggestion-item {
  padding: 8px 16px;
  cursor: pointer;
}

.suggestion-item:hover {
  background-color: #f1f1f1;
}



.checkbox-container {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px; /* Adjust as needed */
}

.custom-checkbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.custom-checkbox-label {
  position: relative;
  display: inline-block;
  width: 20px; /* Adjust size as needed */
  height: 20px; /* Adjust size as needed */
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.custom-checkbox:checked + .custom-checkbox-label::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 12px; /* Adjust size as needed */
  height: 12px; /* Adjust size as needed */
  background-color: #000;
  border-radius: 2px;
}

/* Hide the right side scrollbar */
::-webkit-scrollbar {
  display: none;
}

/* Show scrollbar only on hover */
.container:hover ::-webkit-scrollbar {
  display: block;
}

/* Track */
.container ::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the scrollbar track */
}

/* Handle */
.container ::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar handle */
}

/* Handle on hover */
.container ::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the scrollbar handle on hover */
}
/* disabled edit and delete icon on contact_source = external */
.disabled-external img {
  opacity: 0.4;
  cursor: not-allowed;
}
